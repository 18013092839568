import React, { useState } from "react";
import "./Projects.css";
import one from "../../Images/Projects/1.png";
import two from "../../Images/Projects/2.png";
import three from "../../Images/Projects/3.png";
import four from "../../Images/Projects/4.png";
import five from "../../Images/Projects/5.png";
import six from "../../Images/Projects/6.png";
import seven from "../../Images/Projects/7.png";
import eight from "../../Images/Projects/8.png";
import nine from "../../Images/Projects/9.png";
import ten from "../../Images/Projects/10.png";
import eleven from "../../Images/Projects/11.png";
import twelve from "../../Images/Projects/12.png";
import thirteen from "../../Images/Projects/13.png";
import fourteen from "../../Images/Projects/14.png";
import fivteen from "../../Images/Projects/15.png";
import sixteen from "../../Images/Projects/16.png";
import seventeen from "../../Images/Projects/17.png";
import eighteen from "../../Images/Projects/18.png";
import ninteen from "../../Images/Projects/19.png";
import twenty from "../../Images/Projects/20.png";
import twentyone from "../../Images/Projects/21.png";
import Masonry from "react-responsive-masonry";

const Projects = ({ trackProjectsSelection }) => {
   const images = [
      {
         img: one,
         style: { width: "100%", height: "23%", display: "block" },
      },
      {
         img: two,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: three,
         style: { width: "100%", height: "95%", display: "block" },
      },
      {
         img: four,
         style: { width: "100%", height: "80%", display: "block" },
      },
      {
         img: five,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: six,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: seven,
         style: { width: "100%", height: "15%", display: "block" },
      },
      {
         img: eight,
         style: { width: "100%", height: "100%", display: "block" },
      },
      {
         img: nine,
         style: { width: "100%", height: "60%", display: "block" },
      },
      {
         img: ten,
         style: { width: "100%", height: "50%", display: "block" },
      },
      {
         img: eleven,
         style: { width: "100%", height: "30%", display: "block" },
      },
      {
         img: twelve,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: thirteen,
         style: { width: "100%", height: "23%", display: "block" },
      },
      {
         img: fourteen,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: fivteen,
         style: { width: "100%", height: "95%", display: "block" },
      },
      {
         img: sixteen,
         style: { width: "100%", height: "80%", display: "block" },
      },
      {
         img: seventeen,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: eighteen,
         style: { width: "100%", height: "20%", display: "block" },
      },
      {
         img: ninteen,
         style: { width: "100%", height: "15%", display: "block" },
      },
      {
         img: twenty,
         style: { width: "100%", height: "100%", display: "block" },
      },
      {
         img: twentyone,
         style: { width: "100%", height: "60%", display: "block" },
      },
   ];

   const [showNum, setShowNum] = useState(12);
   const [showImg, setShowImg] = useState(false);
   const [viewedImg, setViewedImg] = useState();
   const viewImg = (image) => {
      setViewedImg(image);
   };
   return (
      <div className="Projects" id="projects">
         <h2>Projects</h2>
         <div className="collage" style={showImg ? { opacity: "20%" } : null}>
            <Masonry columnsCount={3} gutter="1em">
               {images.slice(0, showNum).map((image, i) => (
                  <img
                     key={i}
                     src={image.img}
                     style={image.style}
                     onClick={() => {
                        setShowImg(true);
                        viewImg(image.img);
                        trackProjectsSelection(`${image.img}`);
                     }}
                  />
               ))}
            </Masonry>
            <button onClick={() => setShowNum(showNum + 3)}>view more</button>
         </div>
         <div
            className="viewImg"
            style={showImg ? { display: "block" } : { display: "none" }}
         >
            <div className="photo">
               <img src={viewedImg} alt="" />
            </div>
            <button onClick={() => setShowImg(false)}>
               <p>close</p>
            </button>
         </div>
      </div>
   );
};

export default Projects;
