import React from "react";
import HomeP from "../../Images/Home.png";
import "./Home.css";
const Home = ({ scrollToSection, trackContact }) => {
   return (
      <div className="Home" id="home">
         <div className="text">
            <h1>
               Are you looking to <b>elevate the aesthetics</b> of your space?
            </h1>
            <h1>We are here to make it happen</h1>
            <div className="btn">
               <button
                  onClick={() => {
                     scrollToSection("contact");
                     trackContact();
                  }}
               >
                  <p>Contact Us</p>
               </button>
            </div>
         </div>
         <div className="imgs">
            <img src={HomeP} alt="" />
         </div>
      </div>
   );
};

export default Home;
