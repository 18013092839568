import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = ({ scrollToSection }) => {
   return (
      <div className="Footer">
         <ul>
            <li
               onClick={() => {
                  scrollToSection("home");
               }}
            >
               <p>Home</p>
            </li>
            <li
               onClick={() => {
                  scrollToSection("about");
               }}
            >
               <p>About</p>
            </li>
            <li
               onClick={() => {
                  scrollToSection("services");
               }}
            >
               <p>Services</p>
            </li>
            <li
               onClick={() => {
                  scrollToSection("projects");
               }}
            >
               <p>Projects</p>
            </li>
            <li
               onClick={() => {
                  scrollToSection("testimonials");
               }}
            >
               <p>Testimonials</p>
            </li>
            <li
               onClick={() => {
                  scrollToSection("contact");
               }}
            >
               <p>Contact Us</p>
            </li>
            <li>
               {/* <Link to="/"> */}
                  <p>Log In</p>
               {/* </Link> */}
            </li>
         </ul>
         <p>2024 | &copy; Makho M Construction </p>
         <p>131 Nkupane Road, Boksburg, 1475, South Africa</p>
      </div>
   );
};

export default Footer;
