import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Projects from "./Pages/Projects/Projects";
import Services from "./Pages/Services/Services";
import Testimonials from "./Pages/Testimonials/Testimonials";
import "./App.css";

function App() {
   ReactGA.initialize("G-5HY1TSH78R");
   const [hideHeader, setHideHeader] = useState(true);
   const section1Ref = useRef(null);
   const section2Ref = useRef(null);

   const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
         element.scrollIntoView({ behavior: "smooth" });
      }
   };
   useEffect(() => {
      const handleScroll = () => {
         const currentScrollPos = window.pageYOffset;
         let isScrolledDown = false;
         if (currentScrollPos > 0) {
            isScrolledDown = false;
         } else {
            isScrolledDown = true;
         }
         setHideHeader(isScrolledDown);
      };

      window.addEventListener("scroll", handleScroll);
   }, []);

   const trackPhoneCalls = () => {
      ReactGA.event({
         category: "Call to Action",
         action: "Called",
      });
   };
   const trackContact = () => {
      ReactGA.event({
         category: "Sections Viewed",
         action: "Viewed contact",
      });
   };
   const trackServicesSelection = (service) => {
      ReactGA.event({
         category: "Services Viewed",
         action: `Viewed service: ${service}`,
      });
   };
   const trackProjectsSelection = (service) => {
      ReactGA.event({
         category: "Projects Viewed",
         action: `Viewed image: ${service}`,
      });
   };

   return (
      <div className="App">
         {!hideHeader && (
            <Header
               scrollToSection={scrollToSection}
               trackPhoneCalls={trackPhoneCalls}
               trackContact={trackContact}
            />
         )}
         <Home scrollToSection={scrollToSection} trackContact={trackContact} />
         <About />
         <Services trackServicesSelection={trackServicesSelection} />
         <Projects trackProjectsSelection={trackProjectsSelection} />
         <Testimonials />
         <Contact trackPhoneCalls={trackPhoneCalls} />
         <Footer scrollToSection={scrollToSection} />
      </div>
   );
}

export default App;
