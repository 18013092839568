import React from "react";
import Team from "../../Images/team.png";
import "./About.css";
const About = () => {
  return (
    <div className="AboutPage" id="about">
      <section className="About">
        <h2>
          <b>Who</b> we are
        </h2>
        <div className="theDivide">
          <div className="first">
            <div className="whoWeAre">
              <p>
                Makho M Enterprise is a dynamic construction, logistics &
                events/project management company. Our company's core value
                propostion is to offer our clients innovative design and
                building solutions, strategic project planning and exceptional
                customer and B2B services.
              </p>
              <p>
                Makho M Enterprise is based in Johannesburg and specialize in
                quality built construction, best in industry logistic solutions
                and state of the art events production and management services
              </p>
            </div>
            <div className="vision">
              <h3>
                <b>Our</b> Vision
              </h3>
              <p>
                To be a leading force in our industry. We aim to set new
                standards of excellence and innovation. We aspire to create
                lasting impact and contribute to the success of our clients.
                Committed to continuous growth, we aim to be a beacon of
                reliability, intergrity, creativity. Our vision is build a
                legacy of trust, pushing boundaries and reshaping the landscape
                of possibilities in the business world
              </p>
            </div>
          </div>
          <div className="second">
            <div className="mission">
              <h3>
                <b>Our</b> Mission
              </h3>
              <ul>
                <li>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g id="Complete">
                      <g id="tick">
                        <polyline
                          fill="none"
                          points="3.7 14.3 9.6 19 20.3 5"
                          stroke="#01204e"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <p>
                    To provide world-class construction, deco, and logistics
                    service
                  </p>
                </li>
                <li>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g id="Complete">
                      <g id="tick">
                        <polyline
                          fill="none"
                          points="3.7 14.3 9.6 19 20.3 5"
                          stroke="#01204e"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <p>
                    To continuously improve our quality of services in these
                    ever-changing industries.
                  </p>
                </li>
                <li>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g id="Complete">
                      <g id="tick">
                        <polyline
                          fill="none"
                          points="3.7 14.3 9.6 19 20.3 5"
                          stroke="#01204e"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <p>
                    To always be company of choice, working in partnership with
                    individuals and organizations.
                  </p>
                </li>
                <li>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g id="Complete">
                      <g id="tick">
                        <polyline
                          fill="none"
                          points="3.7 14.3 9.6 19 20.3 5"
                          stroke="#01204e"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                  <p>
                    To optimize our employees' potential through development,
                    empowerment and rewards because we understand they are our
                    most valuable asset.
                  </p>
                </li>
              </ul>
            </div>
            <div className="imgs">
              <img src={Team} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="nhbrc">
        <p>Accredited with:</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 510.28 449.39"
        >
          <path
            class="cls-1"
            d="M10.59,57.25a3.66,3.66,0,0,0,2.93-1.92c.43-1,.65-3.3.65-6.93V18.13L13.66,17a6.34,6.34,0,0,0-1.37-2.19,3.24,3.24,0,0,0-1.7-.49V13.07h6.28L31.09,45.94V23.11c0-3.76-.29-6.2-.9-7.33a3.38,3.38,0,0,0-2.62-1.44V13.07h8.65v1.27a3.56,3.56,0,0,0-2.8,1.92c-.47,1-.71,3.31-.71,6.91V59.34h-.62L15.78,21.66V48.4q0,5.64.89,7.35a3.44,3.44,0,0,0,2.57,1.5v1.28H10.59Z"
          ></path>
          <path
            class="cls-1"
            d="M37.32,58.53V57.25a3,3,0,0,0,2.13-2A79,79,0,0,0,41.89,46l7.67-33.72h.74l9.18,39A22.08,22.08,0,0,0,61,56a2.84,2.84,0,0,0,1.84,1.25v1.28H53.42V57.25a4.93,4.93,0,0,0,2.08-.65c.31-.25.47-.88.47-1.88A14.64,14.64,0,0,0,55.79,53c-.12-.85-.29-1.74-.5-2.67l-1.48-6.61H44.1c-1,4.49-1.53,7.24-1.71,8.22a13.29,13.29,0,0,0-.28,2.36,3.1,3.1,0,0,0,.75,2.4,3.64,3.64,0,0,0,1.76.6v1.28ZM53.19,40.88,49,22,44.72,40.88Z"
          ></path>
          <path
            class="cls-1"
            d="M60,13.07H80.85l.2,11.67h-.88c-.43-3.67-1-6-1.65-7.16s-2.06-1.66-4.19-1.66H72.27V51.08c0,2.65.22,4.29.67,4.92s1.42,1,2.91,1.25v1.28H65.11V57.25c1.56-.22,2.54-.7,2.92-1.43s.57-2.58.57-5.54V15.92H66.53c-2,0-3.42.55-4.16,1.65s-1.29,3.49-1.66,7.17h-.9Z"
          ></path>
          <path
            class="cls-1"
            d="M82.4,57.25A4.17,4.17,0,0,0,85.32,56q.66-.95.66-4.92V20.52c0-2.53-.22-4.16-.66-4.88s-1.42-1.16-2.92-1.3V13.07H93.31v1.27c-1.51.14-2.47.57-2.92,1.3s-.67,2.35-.67,4.88V51.08c0,2.65.23,4.29.67,4.92a4.15,4.15,0,0,0,2.92,1.25v1.28H82.4Z"
          ></path>
          <path
            class="cls-1"
            d="M98.88,18.17q3.41-6.08,8.37-6.07t8.38,6.1q3.69,6.61,3.69,17.62,0,11.16-3.82,17.78c-2.27,3.93-5,5.9-8.25,5.9q-5.19,0-8.6-6.54T95.18,35.82q0-11,3.7-17.65m2.76,33.67c1.52,3.45,3.4,5.18,5.62,5.18s4-1.68,5.57-5.05,2.31-8.75,2.31-16.15-.78-12.91-2.34-16.24-3.4-5-5.54-5-4.08,1.73-5.61,5.18-2.3,8.81-2.3,16.06.76,12.56,2.29,16"
          ></path>
          <path
            class="cls-1"
            d="M121.1,57.25A3.64,3.64,0,0,0,124,55.33c.43-1,.65-3.3.65-6.93V18.13l-.5-1.11a6.17,6.17,0,0,0-1.38-2.19,3.21,3.21,0,0,0-1.7-.49V13.07h6.28l14.23,32.87V23.11q0-5.64-.9-7.33a3.42,3.42,0,0,0-2.63-1.44V13.07h8.66v1.27a3.58,3.58,0,0,0-2.81,1.92c-.47,1-.7,3.31-.7,6.91V59.34h-.63L126.3,21.66V48.4q0,5.64.88,7.35a3.46,3.46,0,0,0,2.57,1.5v1.28H121.1Z"
          ></path>
          <path
            class="cls-1"
            d="M147.84,58.53V57.25a3,3,0,0,0,2.13-2A83.58,83.58,0,0,0,152.41,46l7.67-33.72h.73l9.18,39A22.69,22.69,0,0,0,171.46,56a2.86,2.86,0,0,0,1.84,1.25v1.28h-9.36V57.25A4.92,4.92,0,0,0,166,56.6c.32-.25.48-.88.48-1.88a17.12,17.12,0,0,0-.18-1.77q-.19-1.28-.51-2.67l-1.47-6.61h-9.71c-1,4.49-1.54,7.24-1.72,8.22a14.31,14.31,0,0,0-.28,2.36,3.1,3.1,0,0,0,.76,2.4,3.64,3.64,0,0,0,1.75.6v1.28ZM163.7,40.88,159.48,22l-4.24,18.92Z"
          ></path>
          <path
            class="cls-1"
            d="M174.31,57.25A3.82,3.82,0,0,0,177,55.9c.4-.63.6-2.24.6-4.82V20.52a12.4,12.4,0,0,0-.59-4.79,3.23,3.23,0,0,0-2.65-1.39V13.07h10.4v1.27A3.49,3.49,0,0,0,182,15.59c-.47.69-.7,2.18-.7,4.46v33a6.15,6.15,0,0,0,.21,1.8,1.18,1.18,0,0,0,.91.82,6,6,0,0,0,.88.13c.3,0,.87,0,1.72,0q4.92,0,6.64-1.52c1.15-1,2.27-3.58,3.35-7.71h.94l-1.8,11.91H174.31Z"
          ></path>
          <path
            class="cls-1"
            d="M204.86,57.25c1.44-.29,2.34-.78,2.7-1.48s.54-2.53.54-5.49V20.52q0-3.69-.6-4.8a3.27,3.27,0,0,0-2.64-1.38V13.07h10.22v1.27a3.25,3.25,0,0,0-2.63,1.38c-.41.74-.61,2.34-.61,4.8V33.9H223V20.52c0-2.46-.2-4.06-.59-4.8a3.26,3.26,0,0,0-2.65-1.38V13.07H230v1.27a3.25,3.25,0,0,0-2.63,1.38c-.41.74-.61,2.34-.61,4.8V51.08c0,2.49.2,4.09.61,4.79A3.48,3.48,0,0,0,230,57.25v1.28H219.79V57.25c1.46-.26,2.36-.76,2.71-1.46s.53-2.55.53-5.51V36.92H211.84V51.08c0,2.49.2,4.09.61,4.81a3.38,3.38,0,0,0,2.63,1.36v1.28H204.86Z"
          ></path>
          <path
            class="cls-1"
            d="M235.68,18.17q3.4-6.08,8.36-6.07t8.39,6.1q3.69,6.61,3.69,17.62,0,11.16-3.82,17.78-3.42,5.9-8.26,5.9-5.17,0-8.6-6.54T232,35.82q0-11,3.71-17.65m2.75,33.67c1.53,3.45,3.4,5.18,5.63,5.18s4-1.68,5.57-5.05,2.31-8.75,2.31-16.15-.78-12.91-2.34-16.24-3.41-5-5.54-5-4.09,1.73-5.62,5.18-2.29,8.81-2.29,16.06.76,12.56,2.28,16"
          ></path>
          <path
            class="cls-1"
            d="M257.9,57.25a3.64,3.64,0,0,0,2.93-1.92c.43-1,.65-3.3.65-6.93V20.51c0-2.48-.21-4.09-.63-4.84s-1.37-1.19-2.86-1.33V13.07h7.26l8.46,34.22L282,13.07h7.32v1.27a3.21,3.21,0,0,0-2.64,1.39c-.4.75-.6,2.35-.6,4.78V51.05c0,2.5.2,4.11.6,4.82a3.45,3.45,0,0,0,2.64,1.38v1.28H279V57.25c1.49-.22,2.41-.72,2.78-1.49s.55-2.61.55-5.51V19.4l-9.43,39.13h-.51L263.09,21V48.4q0,5.64.89,7.34a3.41,3.41,0,0,0,2.57,1.51v1.28H257.9Z"
          ></path>
          <path
            class="cls-1"
            d="M290.66,57.25a3.82,3.82,0,0,0,2.63-1.35c.4-.63.61-2.24.61-4.82V20.52c0-2.44-.2-4-.6-4.79a3.22,3.22,0,0,0-2.64-1.39V13.07h19.57l.13,9.83h-.94Q309,18.36,308,17c-.62-.89-2-1.33-4.18-1.33h-5c-.54,0-.87.16-1,.48a6.18,6.18,0,0,0-.18,1.83V33.34h5.69c1.56,0,2.57-.44,3-1.31a17.92,17.92,0,0,0,1-5.27h.86V42.63h-.86a17.51,17.51,0,0,0-1.07-5.29c-.42-.86-1.42-1.29-3-1.29h-5.69v17c0,1.36.23,2.17.69,2.42a13,13,0,0,0,4.16.37q3.74,0,5.52-1.53c1.19-1,2.27-3.48,3.21-7.4h1l-1.67,11.61H290.66Z"
          ></path>
          <path
            class="cls-1"
            d="M322.61,57.25c1.48-.15,2.44-.58,2.87-1.28s.65-2.33.65-4.89V20.52c0-2.53-.22-4.16-.65-4.89s-1.39-1.15-2.87-1.29V13.07h10.32a9.39,9.39,0,0,1,7,2.65c1.72,1.77,2.59,4.81,2.59,9.14,0,3.24-.6,5.71-1.82,7.38a7.54,7.54,0,0,1-3.07,2.39,10.41,10.41,0,0,1,4.33,3.8,13.25,13.25,0,0,1,1.8,7.46q0,8.05-4,11.06a8.4,8.4,0,0,1-5.18,1.58h-12Zm13.85-24.93q2.34-2,2.34-7.22,0-6.15-2.7-8.3a7.23,7.23,0,0,0-4.53-1.18c-.77,0-1.24.12-1.42.37a4.34,4.34,0,0,0-.27,2V33.53l3.5-.1a4.8,4.8,0,0,0,3.08-1.11M337.65,54c1.27-1.37,1.9-4,1.9-7.85s-.74-6.48-2.22-7.88a8,8,0,0,0-5.69-2.12h-1.07c-.15,0-.38,0-.69,0V53.23a3.37,3.37,0,0,0,.53,2.3,4.05,4.05,0,0,0,2.51.51,6.18,6.18,0,0,0,4.73-2"
          ></path>
          <path
            class="cls-1"
            d="M357.49,13.07v1.28a3.49,3.49,0,0,0-2.75,1.32c-.42.7-.63,2.32-.63,4.84v22a34.73,34.73,0,0,0,.79,8.42c1,3.67,2.85,5.51,5.59,5.51q4.53,0,6.07-5.64a40.39,40.39,0,0,0,.86-9.73V23.17q0-5.61-.71-7A3.55,3.55,0,0,0,364,14.35V13.07h8.53v1.28a3.54,3.54,0,0,0-2.8,1.92q-.66,1.5-.66,6.9V41.05a41.37,41.37,0,0,1-1.17,11.11q-2.16,7.34-8.2,7.34c-4,0-6.69-2.41-8.14-7.25A37,37,0,0,1,350.37,42V20.51c0-2.5-.21-4.1-.63-4.81a3.51,3.51,0,0,0-2.7-1.35V13.07Z"
          ></path>
          <path
            class="cls-1"
            d="M373.74,57.25A4.15,4.15,0,0,0,376.65,56q.66-.95.66-4.92V20.52c0-2.53-.22-4.16-.66-4.88s-1.42-1.16-2.91-1.3V13.07h10.9v1.27c-1.5.14-2.47.57-2.92,1.3s-.66,2.35-.66,4.88V51.08q0,4,.66,4.92a4.17,4.17,0,0,0,2.92,1.25v1.28h-10.9Z"
          ></path>
          <path
            class="cls-1"
            d="M385.83,57.25a3.82,3.82,0,0,0,2.63-1.35c.4-.63.6-2.24.6-4.82V20.52c0-2.44-.19-4-.59-4.79a3.24,3.24,0,0,0-2.64-1.39V13.07h10.4v1.27a3.5,3.5,0,0,0-2.73,1.25c-.46.69-.69,2.18-.69,4.46v33a6.2,6.2,0,0,0,.2,1.8,1.19,1.19,0,0,0,.91.82,6,6,0,0,0,.88.13c.31,0,.88,0,1.72,0q4.92,0,6.64-1.52c1.16-1,2.27-3.58,3.35-7.71h.94l-1.8,11.91H385.83Z"
          ></path>
          <path
            class="cls-1"
            d="M408.51,57.25a3.84,3.84,0,0,0,2.63-1.35c.41-.63.61-2.24.61-4.82V20.52c0-2.51-.2-4.12-.59-4.84a3.29,3.29,0,0,0-2.65-1.33V13.07h9.94a12.33,12.33,0,0,1,8.48,3.08q6.26,5.55,6.27,19.8a41.17,41.17,0,0,1-1.13,9.76,22.5,22.5,0,0,1-3.39,7.82,11.12,11.12,0,0,1-9.93,5H408.51Zm7.42-1.81a2.32,2.32,0,0,0,1.83.54,9,9,0,0,0,7.1-3.19q4.29-4.9,4.28-16.76,0-10.58-3.52-15.91a9.21,9.21,0,0,0-8-4.57,2.17,2.17,0,0,0-1.68.51,3.69,3.69,0,0,0-.44,2.21v35a3.77,3.77,0,0,0,.44,2.21"
          ></path>
          <path
            class="cls-1"
            d="M434.94,57.25a3.82,3.82,0,0,0,2.63-1.35c.41-.63.6-2.24.6-4.82V20.52c0-2.44-.19-4-.59-4.79a3.21,3.21,0,0,0-2.64-1.39V13.07h19.57l.13,9.83h-.94q-.46-4.54-1.4-5.88c-.62-.89-2-1.33-4.17-1.33h-5c-.54,0-.87.16-1,.48a5.77,5.77,0,0,0-.19,1.83V33.34h5.69c1.56,0,2.57-.44,3-1.31a17.92,17.92,0,0,0,1-5.27h.87V42.63h-.87a18.27,18.27,0,0,0-1.06-5.29c-.43-.86-1.43-1.29-3-1.29h-5.69v17c0,1.36.23,2.17.69,2.42a13,13,0,0,0,4.16.37c2.49,0,4.34-.51,5.53-1.53s2.26-3.48,3.2-7.4h1l-1.67,11.61H434.94Z"
          ></path>
          <path
            class="cls-1"
            d="M457.45,57.25c1.41-.24,2.29-.76,2.64-1.53s.52-2.59.52-5.45V20.52a12.25,12.25,0,0,0-.58-4.75,3.21,3.21,0,0,0-2.58-1.42V13.07h10.2a8.88,8.88,0,0,1,5.3,1.51q4,2.83,4,10.48c0,3.42-.59,6-1.78,7.88a8.39,8.39,0,0,1-4.87,3.61L477.92,54a8.15,8.15,0,0,0,1.4,2.33,3.2,3.2,0,0,0,1.8.93v1.28h-5.93L466,37.36l-1.62.17V51.07c0,2.54.21,4.15.62,4.83a3.69,3.69,0,0,0,2.71,1.35v1.28H457.45ZM470,33.43C472,31.94,473,29.12,473,25s-.87-6.69-2.62-8.14a5.65,5.65,0,0,0-3.73-1.18c-1.05,0-1.68.14-1.91.42a3.42,3.42,0,0,0-.36,2V35q4-.27,5.69-1.55"
          ></path>
          <path
            class="cls-1"
            d="M484,44.71a34.73,34.73,0,0,0,2.19,7.38q2.19,4.8,5.45,4.8a3.65,3.65,0,0,0,3-2.08A11,11,0,0,0,495.94,49a12.32,12.32,0,0,0-1.29-5.83,31,31,0,0,0-3.55-4.74L488,34.78a17.24,17.24,0,0,1-2.26-3.39,16.39,16.39,0,0,1-1.56-7.18A18.14,18.14,0,0,1,486,15.55q1.84-3.45,4.91-3.45a5.85,5.85,0,0,1,3,1.15,9,9,0,0,0,2,1.16,1,1,0,0,0,.95-.6,6.74,6.74,0,0,0,.4-1.71h.76l.81,14.62H498q-.9-6.6-2.89-9.22t-3.93-2.62a3.19,3.19,0,0,0-2.64,1.66,8.14,8.14,0,0,0-1.12,4.61,10.86,10.86,0,0,0,.86,4.53,16.43,16.43,0,0,0,2.68,4l3.23,3.72a25.15,25.15,0,0,1,4.29,6.41,18,18,0,0,1,1.23,7,17.17,17.17,0,0,1-2.19,9c-1.46,2.42-3.32,3.62-5.59,3.62a6.67,6.67,0,0,1-3.57-1.2A9.3,9.3,0,0,0,486.21,57a.89.89,0,0,0-.87.74,7.66,7.66,0,0,0-.3,1.57h-.8l-1.11-14.62Z"
          ></path>
          <path
            class="cls-1"
            d="M10.59,435.2c1.58-.22,2.57-.66,3-1.34s.58-2.24.58-4.72V403.32c0-2.1-.21-3.47-.65-4.13s-1.4-1.06-2.89-1.24v-1.1H22a12.17,12.17,0,0,1,6,1.31q4.51,2.46,4.52,9.1,0,4.44-2,6.83A9.55,9.55,0,0,1,25,417.23l8.52,15.14a7.07,7.07,0,0,0,1.57,2,4.11,4.11,0,0,0,2,.81v1.1H30.48L20.14,417.93l-1.81.14v11.76c0,2.21.24,3.61.7,4.19s1.48,1,3,1.18v1.1H10.59Zm14.12-20.68c2.19-1.29,3.29-3.74,3.29-7.32s-1-5.81-2.95-7.06a7.59,7.59,0,0,0-4.18-1,3.73,3.73,0,0,0-2.15.37,2.44,2.44,0,0,0-.39,1.69v14.68q4.46-.23,6.38-1.34"
          ></path>
          <path
            class="cls-1"
            d="M38.15,435.2a4.87,4.87,0,0,0,3-1.17c.45-.55.68-1.95.68-4.19V403.31c0-2.11-.23-3.5-.67-4.15s-1.43-1.05-3-1.21v-1.1h22l.14,8.53H59.19q-.52-3.94-1.58-5.1c-.69-.78-2.25-1.16-4.68-1.16H47.31c-.61,0-1,.14-1.13.42a4.06,4.06,0,0,0-.21,1.59v13.31h6.38c1.76,0,2.88-.38,3.37-1.14a12.68,12.68,0,0,0,1.17-4.57h1V422.5h-1a12.93,12.93,0,0,0-1.19-4.59c-.49-.74-1.6-1.12-3.35-1.12H46v14.77q0,1.77.78,2.1a18.76,18.76,0,0,0,4.66.31q4.2,0,6.2-1.32c1.33-.88,2.54-3,3.6-6.42h1.15L60.48,436.3H38.15Z"
          ></path>
          <path
            class="cls-1"
            d="M82.94,397.11a14.48,14.48,0,0,0,2.66,1,1.51,1.51,0,0,0,1.26-.49,4.46,4.46,0,0,0,.64-1.43h.9l.33,12.55h-1a26,26,0,0,0-2.63-6.46,7.31,7.31,0,0,0-6.35-3.73q-4,0-7,4.35t-3,14.34q0,8.25,3,12.87t7.42,4.63a9.47,9.47,0,0,0,1.76-.2,8.1,8.1,0,0,0,1.91-.64,5,5,0,0,0,1.84-1.21,2.56,2.56,0,0,0,.37-1.49v-9.5c0-2.3-.23-3.74-.68-4.3s-1.44-.93-3-1.06v-1.1H92v1.1a3.08,3.08,0,0,0-2,.73c-.57.62-.85,1.9-.85,3.84v11.74q0,1-3.56,2.79a15.64,15.64,0,0,1-6.85,1.75,12.7,12.7,0,0,1-10.21-5.1q-4.57-5.62-4.58-15.08a26.77,26.77,0,0,1,3.65-14.27q4-6.54,10.65-6.55a12.3,12.3,0,0,1,4.73,1"
          ></path>
          <path
            class="cls-1"
            d="M93.43,435.2c1.68-.18,2.77-.54,3.26-1.09s.75-2,.75-4.27V403.31c0-2.19-.25-3.6-.75-4.24s-1.58-1-3.26-1.12v-1.1h12.22V398c-1.68.12-2.77.5-3.26,1.12s-.75,2.05-.75,4.24v26.53c0,2.29.25,3.72.75,4.27s1.58.91,3.26,1.09v1.1H93.43Z"
          ></path>
          <path
            class="cls-1"
            d="M109.19,424.31a25,25,0,0,0,2.45,6.4c1.64,2.78,3.67,4.16,6.11,4.16a4.24,4.24,0,0,0,3.41-1.8,7.86,7.86,0,0,0,1.44-5,8.81,8.81,0,0,0-1.45-5.07,28.24,28.24,0,0,0-4-4.11l-3.52-3.17a14.89,14.89,0,0,1-2.54-2.94,11.57,11.57,0,0,1-1.76-6.23,13,13,0,0,1,2.08-7.52,6.42,6.42,0,0,1,5.51-3,7.88,7.88,0,0,1,3.4,1,11,11,0,0,0,2.27,1,1.1,1.1,0,0,0,1.06-.52,4.47,4.47,0,0,0,.45-1.49H125l.91,12.7h-1q-1-5.74-3.23-8a6.39,6.39,0,0,0-4.41-2.27,3.83,3.83,0,0,0-3,1.44,5.89,5.89,0,0,0-1.26,4,7.63,7.63,0,0,0,1,3.93,14.89,14.89,0,0,0,3,3.46l3.63,3.23a22.61,22.61,0,0,1,4.8,5.57,12.57,12.57,0,0,1,1.38,6.05,12.4,12.4,0,0,1-2.45,7.84,7.64,7.64,0,0,1-6.27,3.14,9,9,0,0,1-4-1,11.66,11.66,0,0,0-2.38-1.05,1,1,0,0,0-1,.64,6.1,6.1,0,0,0-.33,1.37h-.9l-1.23-12.69Z"
          ></path>
          <path
            class="cls-1"
            d="M130.31,396.85h23.36l.22,10.13h-1c-.49-3.18-1.11-5.26-1.85-6.22s-2.32-1.44-4.71-1.44h-2.3v30.52c0,2.3.25,3.72.74,4.27s1.59.91,3.27,1.09v1.1H136v-1.1c1.75-.2,2.84-.61,3.27-1.25s.64-2.24.64-4.81V399.32h-2.32c-2.29,0-3.84.48-4.67,1.43s-1.45,3-1.86,6.23h-1Z"
          ></path>
          <path
            class="cls-1"
            d="M155.2,435.2c1.59-.22,2.58-.66,3-1.34s.59-2.24.59-4.72V403.32c0-2.1-.22-3.47-.66-4.13s-1.4-1.06-2.9-1.24v-1.1h11.44a12.1,12.1,0,0,1,6,1.31q4.53,2.46,4.53,9.1,0,4.44-2,6.83a9.55,9.55,0,0,1-5.46,3.14l8.51,15.14a7.26,7.26,0,0,0,1.58,2,4.11,4.11,0,0,0,2,.81v1.1H175.1l-10.34-18.37-1.81.14v11.76c0,2.21.23,3.61.7,4.19s1.48,1,3,1.18v1.1H155.2Zm14.13-20.68c2.19-1.29,3.29-3.74,3.29-7.32s-1-5.81-3-7.06a7.59,7.59,0,0,0-4.18-1,3.69,3.69,0,0,0-2.14.37c-.27.24-.4.8-.4,1.69v14.68c3-.15,5.1-.6,6.38-1.34"
          ></path>
          <path
            class="cls-1"
            d="M182.91,436.3v-1.1a3.17,3.17,0,0,0,2.39-1.74,58.4,58.4,0,0,0,2.73-8l8.61-29.27h.83L207.76,430a16.65,16.65,0,0,0,1.64,4.15,3.37,3.37,0,0,0,2.07,1.09v1.1H201v-1.1a7,7,0,0,0,2.34-.56c.35-.23.52-.78.52-1.65a10.69,10.69,0,0,0-.2-1.53,19.06,19.06,0,0,0-.57-2.32l-1.65-5.74H190.51c-1.07,3.91-1.71,6.28-1.92,7.15a9.09,9.09,0,0,0-.31,2,2.33,2.33,0,0,0,.84,2.08,4.91,4.91,0,0,0,2,.53v1.1ZM200.7,421,196,404.57,191.21,421Z"
          ></path>
          <path
            class="cls-1"
            d="M208.37,396.85h23.36L232,407h-1c-.49-3.18-1.11-5.26-1.85-6.22s-2.32-1.44-4.71-1.44h-2.3v30.52c0,2.3.25,3.72.74,4.27s1.59.91,3.27,1.09v1.1h-12v-1.1c1.75-.2,2.84-.61,3.27-1.25s.64-2.24.64-4.81V399.32h-2.32c-2.29,0-3.84.48-4.67,1.43s-1.45,3-1.86,6.23h-1Z"
          ></path>
          <path
            class="cls-1"
            d="M233.47,435.2c1.68-.18,2.77-.54,3.26-1.09s.75-2,.75-4.27V403.31c0-2.19-.25-3.6-.75-4.24s-1.58-1-3.26-1.12v-1.1H245.7V398c-1.69.12-2.77.5-3.27,1.12s-.76,2.05-.76,4.24v26.53c0,2.29.26,3.72.76,4.27s1.58.91,3.27,1.09v1.1H233.47Z"
          ></path>
          <path
            class="cls-1"
            d="M252,401.27q3.81-5.26,9.38-5.26t9.4,5.29q4.14,5.74,4.14,15.29T270.59,432q-3.82,5.13-9.26,5.13-5.81,0-9.64-5.68t-3.9-14.88q0-9.54,4.16-15.32M255,430.49c1.71,3,3.81,4.51,6.3,4.51s4.52-1.47,6.25-4.39,2.6-7.59,2.6-14-.88-11.2-2.63-14.09-3.82-4.34-6.22-4.34-4.58,1.5-6.29,4.5-2.58,7.64-2.58,13.93.85,10.91,2.57,13.9"
          ></path>
          <path
            class="cls-1"
            d="M276.87,435.2c1.7-.26,2.8-.81,3.28-1.67s.73-2.87.73-6V401.24l-.57-1a5.64,5.64,0,0,0-1.54-1.91,4.38,4.38,0,0,0-1.9-.42v-1.1h7.05l15.95,28.53V405.57q0-4.9-1-6.37c-.45-.64-1.43-1.05-2.95-1.25v-1.1h9.7V398a4.14,4.14,0,0,0-3.14,1.66c-.52.88-.79,2.88-.79,6V437H301L282.7,404.31v23.2c0,3.27.32,5.39,1,6.38a4.17,4.17,0,0,0,2.88,1.31v1.1h-9.7Z"
          ></path>
          <path
            class="cls-1"
            d="M336.47,397.11a17,17,0,0,0,2.64,1,1.65,1.65,0,0,0,1.11-.46,2.55,2.55,0,0,0,.72-1.46h.87l.39,13.4h-.95a24.27,24.27,0,0,0-2.16-6.44q-2.55-4.61-6.84-4.6t-7.09,4.95q-2.76,4.95-2.76,13.62,0,8,2.89,12.74t7.34,4.77a9.1,9.1,0,0,0,5.91-2.22,19.69,19.69,0,0,0,3.41-3.78l.77,1.11a17.26,17.26,0,0,1-3.41,4.39,10.88,10.88,0,0,1-7.53,3.2q-6.66,0-10.57-6.14-3.61-5.68-3.61-14.18,0-8.7,3.81-14.62,4-6.19,10.46-6.2a12.2,12.2,0,0,1,4.6,1"
          ></path>
          <path
            class="cls-1"
            d="M349.52,401.27q3.81-5.26,9.38-5.26t9.4,5.29q4.14,5.74,4.14,15.29T368.16,432q-3.82,5.13-9.26,5.13-5.82,0-9.64-5.68t-3.89-14.88c0-6.36,1.37-11.47,4.15-15.32m3.09,29.22c1.71,3,3.81,4.51,6.3,4.51s4.52-1.47,6.25-4.39,2.6-7.59,2.6-14-.88-11.2-2.63-14.09-3.82-4.34-6.22-4.34-4.58,1.5-6.29,4.5S350,410.3,350,416.59s.86,10.91,2.57,13.9"
          ></path>
          <path
            class="cls-1"
            d="M386.26,396.85V398c-1.59.15-2.62.54-3.09,1.15s-.7,2-.7,4.2v19.08a23.72,23.72,0,0,0,.88,7.3q1.66,4.79,6.28,4.78a6.68,6.68,0,0,0,6.8-4.89,27.13,27.13,0,0,0,1-8.45V405.61c0-3.24-.26-5.27-.8-6.1a4.14,4.14,0,0,0-3.07-1.55v-1.11h9.57V398c-1.61.25-2.65.81-3.14,1.67s-.74,2.86-.74,6v15.52a28,28,0,0,1-1.31,9.64q-2.41,6.38-9.2,6.38a9,9,0,0,1-9.12-6.29,25.17,25.17,0,0,1-1.31-8.91V403.31c0-2.17-.23-3.56-.7-4.17s-1.47-1-3-1.18v-1.11Z"
          ></path>
          <path
            class="cls-1"
            d="M404.28,435.2c1.7-.26,2.8-.81,3.28-1.67s.73-2.87.73-6V401.24l-.57-1a5.64,5.64,0,0,0-1.54-1.91,4.38,4.38,0,0,0-1.9-.42v-1.1h7l16,28.53V405.57q0-4.9-1-6.37c-.45-.64-1.43-1.05-2.95-1.25v-1.1H433V398a4.15,4.15,0,0,0-3.15,1.66c-.52.88-.79,2.88-.79,6V437h-.71l-18.27-32.69v23.2c0,3.27.33,5.39,1,6.38A4.2,4.2,0,0,0,414,435.2v1.1h-9.7Z"
          ></path>
          <path
            class="cls-1"
            d="M453.54,397.11a17.28,17.28,0,0,0,2.65,1,1.61,1.61,0,0,0,1.1-.46,2.5,2.5,0,0,0,.73-1.46h.87l.39,13.4h-.95a23.92,23.92,0,0,0-2.16-6.44q-2.55-4.61-6.84-4.6t-7.1,4.95q-2.75,4.95-2.75,13.62,0,8,2.89,12.74t7.34,4.77a9.1,9.1,0,0,0,5.91-2.22,19.69,19.69,0,0,0,3.41-3.78l.77,1.11a17.26,17.26,0,0,1-3.41,4.39,10.87,10.87,0,0,1-7.52,3.2q-6.67,0-10.58-6.14-3.62-5.68-3.61-14.18,0-8.7,3.81-14.62,4-6.19,10.46-6.2a12.12,12.12,0,0,1,4.59,1"
          ></path>
          <path
            class="cls-1"
            d="M461.88,435.2c1.68-.18,2.77-.54,3.26-1.09s.75-2,.75-4.27V403.31c0-2.19-.25-3.6-.75-4.24s-1.58-1-3.26-1.12v-1.1H474.1V398c-1.68.12-2.77.5-3.26,1.12s-.75,2.05-.75,4.24v26.53c0,2.29.25,3.72.75,4.27s1.58.91,3.26,1.09v1.1H461.88Z"
          ></path>
          <path
            class="cls-1"
            d="M475.44,435.2a4.84,4.84,0,0,0,2.95-1.17c.45-.55.68-1.95.68-4.19V403.31c0-2.11-.23-3.5-.67-4.15s-1.43-1.05-3-1.21v-1.1H487.1V398A4.48,4.48,0,0,0,484,399c-.52.6-.78,1.89-.78,3.88v28.65a4.25,4.25,0,0,0,.23,1.55,1.25,1.25,0,0,0,1,.72,9.06,9.06,0,0,0,1,.12c.34,0,1,0,1.93,0,3.67,0,6.15-.43,7.45-1.31s2.54-3.1,3.76-6.69h1.05l-2,10.33H475.44Z"
          ></path>
          <polygon
            class="cls-1"
            points="10.59 81.22 10.59 374.07 36 374.07 36 194.43 62.58 374.07 100.41 374.07 100.41 81.22 80.85 81.22 80.85 258.49 52.98 81.22 10.59 81.22"
          ></polygon>
          <path
            class="cls-1"
            d="M160.59,81.22V200.34a2.24,2.24,0,0,1-2.24,2.24h-7.9a2.25,2.25,0,0,1-2.24-2.24V81.22H111.68V374.07h36.53V231.55a2.25,2.25,0,0,1,2.24-2.24h7.9a2.24,2.24,0,0,1,2.24,2.24V374.07h40.14V81.22Z"
          ></path>
          <path
            class="cls-1"
            d="M280.37,214.56s18.84-3.69,18.84-19.86V105.85c0-3.68-2.87-24.63-21.91-24.63H213.41a3.06,3.06,0,0,0-3.06,3v289.8H281s18.22-.85,18.22-24.39V241.79s1.84-27.23-18.84-27.23m-17.6,120.59c0,10.23-11.67,11.66-13.51,11.46s-2-3.28-2-3.28V232.58s4.74.27,11,1.27c4.55.72,4.55,7.32,4.55,7.32Zm0-133.08c0,5.94-5.53,6.76-8,6.76h-7.55V108.1C263,108.1,262.77,119,262.77,119Z"
          ></path>
          <path
            class="cls-1"
            d="M397.28,194.7V105.85c0-4.92-2.87-24.63-21.92-24.63h-63.7V374.07l35.87.2V230.12s3.16.44,6,.88a8.63,8.63,0,0,1,7,7.72V374.34l36.75.22V241.79c0-8.28,0-19.55-18.84-27.23,0,0,18.84-3.69,18.84-19.86m-34.2,7.37c0,5.94-5.52,6.76-8,6.76h-7.55V108.1c15.76,0,15.55,10.85,15.55,10.85Z"
          ></path>
          <path
            class="cls-1"
            d="M499.69,263.29v86.8c0,9.75-8.25,24-20.73,24H430.27a17.78,17.78,0,0,1-17.73-17.73V99c0-9.66,11.14-17.73,17.73-17.73h69.42V188.15H464.22V120c0-4.56-1.44-8.42-3.3-9.59-2.56-1.6-5.92-3.31-9.8-3.31s-5.41,2.66-5.41,9.21V348.66s8.28,2,12.57-.41a12.9,12.9,0,0,0,7-11.67v-73.5Z"
          ></path>
        </svg>
      </section>
    </div>
  );
};

export default About;
